import { AiOutlineCloseSquare } from "react-icons/ai";
import backdrop from './icon-10-min.png';
import buttonBack from './icon-12-min.png';

const Popup = ({ setIsOpenPopup }) => {
    return (
        <div
            onClick={() => setIsOpenPopup(false)}
            style={{
                position: "fixed",
                background: "rgba(0,0,0,0.6)",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
                overflow: "hidden",
            }}
        >
            {/* Content */}
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    position: "relative",
                    borderRadius: "20px",
                    width: "90%",
                    maxWidth: "300px",
                    height: "auto",
                    maxHeight: "80vh",
                    animation: "dropTop .3s linear",
                    backgroundImage: `url(${backdrop})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    zIndex: 999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={backdrop}
                    alt="Backdrop"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "20px",
                    }}
                />

                <div
                    onClick={() => setIsOpenPopup(false)}
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: 20,
                        right: 20,
                        zIndex: 1001
                    }}
                >
                    <img width={20} alt='Close' src={buttonBack} />
                </div>
            </div>
        </div>
    );
};

export default Popup;

import './App.css';
import { useEffect, useState } from 'react';
import LandingPage from './components/LandingPage';
import SearchFound from './components/SearchFound';
import { predefinedLocations } from './appconst';
import { Button } from '@mui/material';

function App() {
  const [location, setLocation] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [nearLocation, setNearByLocation] = useState('');
  const [first, setFirst] = useState(0);

  // useEffect(() => {
  //   console.log('predefined', predefinedLocations);
  //   // Check if geolocation is supported
  //   if ('geolocation' in navigator) {
  //     const watchId = navigator.geolocation.watchPosition(
  //       position => {
  //         setLocation({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         });
  //       },
  //       error => {
  //         console.error('Error getting user location:', error);
  //       }
  //     );

  //     // Clean up the watchPosition when the component unmounts
  //     return () => {
  //       navigator.geolocation.clearWatch(watchId);
  //     };
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // }, []);

  // useEffect(() => {
  //   if (location) {
  //     const isNearPredefinedLocation = predefinedLocations.some(loc => {
  //       const distance = calculateDistance(
  //         loc.latitude,
  //         loc.longitude,
  //         location.latitude,
  //         location.longitude
  //       );

  //       return distance < 100; // You can adjust the distance threshold as needed
  //     });
  //     setShowModel(isNearPredefinedLocation);
  //   }
  // }, [location]);

  // useEffect(() => {
  //   if (location) {
  //     const nearbyLocation = predefinedLocations.reduce((nearest, loc) => {
  //       const distance = calculateDistance(
  //         loc.latitude,
  //         loc.longitude,
  //         location.latitude,
  //         location.longitude
  //       );

  //       setFirst(distance);
  //       if ((distance > 100 && distance <= 200) && (!nearest || distance < nearest.distance)) {
  //         return { location: loc.locationName, distance: distance };
  //       } else {
  //         return nearest;
  //       }
  //     }, null);

  //     if (nearbyLocation) {
  //       setNearByLocation(nearbyLocation.location);
  //     } else {
  //       setNearByLocation(null);
  //     }
  //   }
  // }, [location, predefinedLocations]);

  // useEffect(() => {
  //   playSound()
  // }, []);

  function playSound() {
    // Play audio when the component mounts
    const audioElement = document.getElementById('audio');
    audioElement.play();
  }
  // Empty dependency array to ensure this effect runs only once

  // Function to calculate distance between two coordinates (in meters)
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  return (
    <div onClick={playSound} style={{ margin: 0, padding: 0, height: "100vh", width: "100vw" }}>
      <audio id="audio" src="/audio/Vesak.mp3" autoPlay loop style={{ display: 'none' }} />
      <SearchFound />
      {/* {(showModel ? (
        <SearchFound />
      ) : (
        <LandingPage locationName={nearLocation} />
      ))} */}
    </div>
  );
}

export default App;

import React, { useState, useRef } from 'react';
import { styled } from '@mui/system';
import Ar from './3d.gif';
import logo from './KeellsVesaklogo-01.png';
import backdrop from './Web-06-min-min.png';
import button from './Web-08-min.png';
import buttonBack from './back.png';
import info from './Web-07-min.png';
import Popup from "./Popup";
import './styles.css';

const SearchFoundContainer = styled('div')({
    backgroundColor: '#1B1B1B',
    backgroundImage: `url(${backdrop})`,
    backgroundSize: 'contain', // Ensure the background covers the whole container
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: 'no-repeat', // Prevent the background from repeating
    width: '100vw', // Full viewport width
    height: '100vh', // Full viewport height
    position: "relative"

});

function SearchFound() {
    const [clicked, setClicked] = useState(false);
    const modelViewerRef = useRef(null); // Create a ref for the model-viewer
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const openAR = () => {
        setClicked(true);
        // handleActivateAR();
    };

    // Function to handle activating AR mode
    const handleActivateAR = () => {
        if (modelViewerRef.current) {
            modelViewerRef.current.activateAR(); // Call activateAR on the ref
            setClicked(false);
        }
    };

    const [isArSupported, setIsArSupported] = useState(true);

    const handleInstallAR = () => {
        // Redirect the user to Google Play Store to install AR services
        window.location.href = 'https://play.google.com/store/apps/details?id=com.google.ar.core';
    };

    return (
        <>
            {!clicked && (<div style={{
                height: "100vh", width: "100vw", display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
            }}>

                <SearchFoundContainer>
                    <div onClick={setIsOpenPopup.bind(this, true)} style={{ width: "100%", position: 'absolute', top: "24%", left: "15%", zIndex: 0 }}>
                        <img width={45} alt='logo' src={info} />
                    </div>


                    <div className='avatar' onClick={openAR} style={{ position: 'absolute', bottom: "24%", textAlign: "center", zIndex: 0 }}>
                        <img width={10} alt='logo' src={button} />
                    </div>
                    {isOpenPopup && <Popup setIsOpenPopup={setIsOpenPopup} />}


                </SearchFoundContainer>
            </div>
            )}
            {clicked && (
                <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#1B1B1B" }}>


                    <model-viewer
                        ref={modelViewerRef} // Assign ref to the model-viewer
                        style={{ height: "100vh", width: "100vw", justifyContent: "center", alignItems: "center", display: "flex", backgroundColor: "#1B1B1B" }}
                        ios-src={'/model/Ar.usdz'}
                        src={'/model/Ar.glb'}
                        alt="A 3D model of an astronaut"
                        ar
                        ar-modes='scene-viewer quick-look'
                        camera-controls
                        autoplay
                    >

                        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", position: "absolute", }} onClick={() => setClicked(false)} >
                            <img width={120} alt='logo' src={buttonBack} />

                        </div>
                        <button slot="ar-button" style={{
                            backgroundColor: "#FF0331",
                            borderRadius: "10px",
                            border: "none",
                            position: "absolute",
                            bottom: "20%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: 'white',
                            padding: "10px 15px"
                        }}>
                            Click to View AR
                        </button>



                    </model-viewer>
                </div>
            )
            }
        </>
    );
}

export default SearchFound;
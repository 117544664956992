import React from 'react';
import { styled } from '@mui/system';
import logo from './logo.png'
import place from './eco-house.gif'
import all from './locations.gif'
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const LandingPageContainer = styled('div')({
    backgroundColor: '#70BB36',
    color: 'white',
    padding: '20px',
    fontSize: '24px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh"
});

function LandingPage({ locationName = "" }) {


    return (
        <LandingPageContainer>
            <img width={150} alt='logo' src={logo} style={{ position: "absolute", top: 0 }} />
            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }}>

                <div style={{ borderRadius: "50%", backgroundColor: "white", width: 150, height: 150, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img width={100} src={place} alt="place icon" />
                </div>
                <span style={{ marginTop: 30 }}> Go to your nearest Keells store to enjoy our all new AR experience. </span>
                {locationName && <span style={{ marginTop: 20, fontSize: 18 }}>Your nearest outlet is <br />  <strong>{locationName} </strong></span>}
                {!locationName &&
                    <div style={{ position: "absolute", bottom: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ borderRadius: "50%", backgroundColor: "white" }}>
                            <IconButton style={{ color: 'white' }} onClick={() => {
                                const googleMapsLink = 'https://www.google.com/maps/d/u/0/viewer?mid=1nNoynKpGqneC_6Ud1o9Z8Gdy94XembM&ll=6.917289999999999%2C79.84856&z=10';
                                window.open(googleMapsLink, '_blank');
                            }}><img width={30} src={all} alt="locations icon" /></IconButton>
                        </div>

                        <span style={{ marginTop: 20, fontSize: 18 }}>All Outlet Locations  </span>
                    </div>
                }



            </div>





        </LandingPageContainer>
    );
}

export default LandingPage;
